@import '~@fortawesome/fontawesome-free/css/all.css';
@import '../../mdb-ecommerce/css/bootstrap.min.css';
@import '../../mdb-ecommerce/css/mdb.min.css';
@import '../../mdb-ecommerce/css/mdb.ecommerce.min.css';
@import '../../mdb-ecommerce/css/addons-pro/steppers.min.css';
@import './cookie_consent.css';
@import 'fonts';

:root {
    --info-color: #0099CC;
}

html {
    height: 100%;
}

body {
    height:100%;
    overflow-x: hidden;
    padding:0 !important;
    color: #4f4f4f;

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

main > div.container-fluid {
    max-width: 1200px;
}

.tooltip.md-tooltip .tooltip-inner {
    font-size: .9rem !important;
}

.body-content {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}

.footer-copyright {
    height: 30px;
}

strong {
    font-weight: 700!important;
}
.dropdown-menu {
    box-shadow: 0 8px 15px rgba(0,0,0,0.1);
    border-color: #e1e1e1;
    -webkit-border-radius: 0 0 0 0 !important;
    -moz-border-radius: 0 0 0 0 !important;
    border-radius: 0 0 0 0 !important;
    min-width: 16em;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a,
.dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #FFF2C9 !important;
    color: var(--secondary-color) !important;
    box-shadow: unset;
}

li.dropdown:hover > ul.dropdown-menu,
li.dropdown-submenu:hover > ul.dropdown-menu,
li.dropdown:hover > div.dropdown-menu,
li.dropdown-submenu:hover > div.dropdown-menu {
    display: block;
}

.dropdown-submenu > a::after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -110%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

/*.dropdown-submenu > .dropdown-menu {*/
/*    top: 0;*/
/*    left: 100%;*/
/*    margin-top: -6px;*/
/*    margin-left: -1px;*/
/*    -webkit-border-radius: 0 0 0 0;*/
/*    -moz-border-radius: 0 0 0 0;*/
/*    border-radius: 0 0 0 0;*/
/*}*/

.mt-120 {
    margin-top: 120px;
}

.mw-25 {
    max-width: 25%;
}

.card.card-cascade .view.view-cascade {
    box-shadow: unset;
}

.top-bar {
    height:40px;
    background-color: var(--secondary-color);
    z-index: 1035;
    padding: 0 .5rem;
    box-shadow: unset !important;
}

#topbarNav {
    height: 30px;
}

nav .navbar-brand img {
    max-height: 70px;
}

#sylius-cart-button::after,
#topbarNav li.nav-item > .nav-link.dropdown-toggle::after,
#taxons-topbar-collapse li.nav-item > .nav-link.dropdown-toggle::after {
    content: none;
}

#topbarNav .navbar-nav  > li.nav-item > .nav-link::after {
    display:none !important;
}

.navbar .navbar-toggler-icon {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#topbarNav.navbar-nav {
    flex-direction: row !important;
}

#topbarNav a.nav-link {
    color: var(--primary-color);
}

#topbarNav a.nav-link:focus,
#topbarNav a.nav-link:hover {
    color: #ffffff;
}

.skin-tp #taxons-topbar-collapse .navbar .navbar-nav .nav-item .nav-link {
    color: var(--primary-color);
}

.skin-tp #taxons-topbar-collapse .navbar .navbar-nav .nav-item .nav-link:hover {
    color: var(--secondary-color);
}

.counter.counter-floating.counter-lg-icon {
    top: -8px;
    margin-left: -25px;
}

a {
    color: var(--primary-color);
}

a:focus, a:hover {
    color: var(--secondary-color);
}

.bg-primary {
    background-color: var(--primary-color) !important;
}

.tp-blue,
.tp-blue::after {
    background-color: var(--primary-color) !important;
}

.tp-orange {
    background-color: var(--secondary-color);
}

.text-tp-blue {
    color: var(--primary-color) !important;
}

.text-tp-orange {
    color: var(--secondary-color);
}

.text-light-grey {
    color: rgba(0,0,0,.4);
}

.rgba-tp-orange, .rgba-tp-orange::after {
    background-color: rgba(235,156,18,0.7);
}

.olive {
    background-color: #BFD33A !important;
}

.stepper-horizontal li:not(:first-child)::before,
.stepper-horizontal li:not(:last-child)::after {
    background-color: transparent !important;
}

ul.stepper li a {
    padding: 0.5rem !important;
}

.stepper-icon {
    min-width: 64px;
}

.custom-address-book-select input.select-dropdown {
    font-size: 1.3rem !important;
}

.text-grey-lighten-3 {
    color: #eee !important;
}

.btn {
    text-transform: unset;
    color: rgba(0,0,0,.6);
}

.btn.btn-sm {
    padding: .4rem 1rem !important;
    font-size: .78rem;
}

.btn:focus {
    text-transform: unset;
    color: rgba(0,0,0,.8);
}

.btn-light-grey {
    background-color: #E0E1E2;
}

.bg-light-grey {
    background-color: #E0E1E2 !important;
}

.btn-outline-primary,
.btn-outline-primary:hover {
    color: var(--primary-color) !important;
    background-color: transparent !important;
    border: 2px solid var(--primary-color) !important;
}

.btn-primary {
    color: #fff !important;
    background-color: var(--primary-color) !important;
}

.btn-danger {
    background-color: #db2828;
    color: #ffffff;
    background-image: none;
    text-shadow: none;
}

.btn-primary:hover {
    color: var(--secondary-color) !important;
}

.btn-secondary {
    color: var(--primary-color) !important;
    background-color: var(--secondary-color) !important;
}

.btn-secondary:hover {
    color: #ffffff !important;
    background-color: var(--secondary-color) !important;
}

.btn-secondary-bordered {
    color: var(--primary-color) !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid var(--primary-color);
}

.btn-secondary-bordered:hover {
    color: #ffffff !important;
    background-color: var(--secondary-color) !important;
}

.btn.huge {
    font-size: 1.42857143rem;
}

.btn.large {
    font-size: 1.14285714rem;
}

.btn-group-vertical .btn {
    margin: 0 !important;
    width: 100% !important;
}

.border-w-3 {
    border-width: 3px !important;
}

.form .btn-floating {
    float: right;
    position: relative;
    bottom: 3rem;
    margin-right: 0;
}

/* remove arrows in number inputs: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* remove arrows in number inputs: Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.card-taxon, .card-product {
    max-width: 260px;
}

.card-product {
    height: 348px !important;
}

.carousel-inner .carousel-item .card-product {
    height: 100% !important;
}

.validation-error-label {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.142857em 0 .14285714em;
    background-color: #db2828 !important;
    border-color: #db2828 !important;
    color: #fff !important;
    background-image: none;
    padding: .5833em .833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
    border-radius: .28571429rem;
    -webkit-transition: background .1s ease;
    transition: background .1s ease;
}

.label.hidden,
.labels.hidden .label {
    display: none !important;
}

.form .required > .checkbox::after, .form .required > label::after {
    margin: -.2em 0 0 .2em;
    content: '*';
    color: #db2828;
}

.pointing.label {
    position: relative;
}

.pointing.label::before {
    border-width: 1px 0 0 1px;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
}

.pointing.label::before {
    position: absolute;
    content: '';
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-image: none;
    z-index: 2;
    width: .6666em;
    height: .6666em;
    -webkit-transition: background .1s ease;
    transition: background .1s ease;
}

.pointing.label::before {
    background-color: inherit;
    background-image: inherit;
    border-width: none;
    border-style: solid;
    border-color: inherit;
}

.switch label input[type="checkbox"]:checked + .lever::after {
    left: 1.5rem;
    background-color: var(--primary-color);
}

footer.page-footer a {
    color: var(--primary-color);
}

footer.page-footer a:hover {
    color: var(--secondary-color);
}

.fa-stack.disabled > i,
.fas.disabled,
.fab.disabled,
.far.disabled {
    opacity: 0.5;
}

.side-nav .treeview-animated .treeview-animated-list .treeview-animated-items .nested::before {
    position: absolute;
    left: 5px;
    display: block;
    width: 5px;
    height: 100%;
    content: "";
    background-color: transparent;
}

.side-nav .treeview-animated .treeview-animated-list .treeview-animated-items .open {
    background-color: #FFF2C9 !important;
    color: var(--secondary-color) !important;
    transition: all .1s linear;
}

.side-nav .treeview-animated .treeview-animated-list .treeview-animated-items .open span,
.side-nav .treeview-animated .treeview-animated-list .treeview-animated-items .closed .fa-angle-right.down {
    color: var(--secondary-color) !important;
}

.side-nav a {
    color: #ffffff !important;
    line-height: unset !important;
    height: unset !important;
}

.side-nav .fa-angle-down.rotate-icon {
    position: unset;
}

.side-nav .collapsible a.collapsible-header {
    padding-left: 0;
    margin-left: -10px;
    width: 60px;
    padding-right: 20px;
    text-align: center;
}

.side-nav .collapsible a.collapsible-header > i.rotate-icon {
    margin-right: unset;
}

.loading.form::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: rgba(255,255,255,.8);
    width: 100%;
    height: 100%;
    z-index: 100;
}

.loading.form {
    position: relative;
    cursor: default;
    pointer-events: none;
}

.loading.form::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -1.5em 0 0 -1.5em;
    width: 3em;
    height: 3em;
    -webkit-animation: container-rotate .6s linear;
    animation: container-rotate .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.1);
    border-style: solid;
    border-width: .2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
    visibility: visible;
    z-index: 101;
}

.header-banner {
    width: 100vw;
    margin-left: -15px;
    margin-top: -1.5rem;
}

/* alerts */
.alert {
   border-radius: 0;
   border: none;
}

.alert .media .fa-check,
.alert .media .fa-times {
    color: #ffffff;
}

.alert .media .fa-stack {
    font-size: 1em;
}

.alert .media .media-body {
    display: block;
    line-height: 2em;
    min-height: 2em;
    padding-left: 2.5em;
}

.alert .media .media-body .alert-message,
.alert .media .media-body .alert-title {
    display: inline-block;
    vertical-align: middle;
    line-height: 1em;
    margin-bottom: 0.25rem;
}

.cart.popup {
    min-width: 300px;
}

.flex-even {
    flex: 1;
}

.number-input.number-input input[type="number"] {
    width: 100%;
    padding: .5rem;
    border: 0 solid #ced4da;
    border-right-width: 1px;
    border-left-width: 1px;
    font-size: 1rem;
    height: 2rem;
    color: #495057;
}

.number-input input[type="number"] {
    text-align: center;
}

.number-input a {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input.number-input a {
    width: 2.5rem;
}

.number-input.number-input a::before,
.number-input.number-input a::after {
    width: .7rem;
    background-color: #495057;
}

.number-input a::before,
.number-input a::after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.number-input.number-input a::after {
    width: .7rem;
    background-color: #495057;
}

.number-input a.plus::after {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input.number-input {
    border: 1px solid #ced4da;
    width: 11.6rem;
    border-radius: .25rem;
}

.list-group-flush .list-group-item {
    border-color: rgba(0,0,0,0.1);
}

.product-gallery figure:not(.main-img) {
    position: absolute;
    left: 0;
    right: 0;
}

.skin-tp .classic-tabs .nav.tabs-primary {
    background-color: #fff !important;
}

.skin-tp .classic-tabs .nav.tabs-primary li.nav-item {
    border-bottom: 1px solid #dee2e6;
}

.skin-tp .classic-tabs .nav.tabs-primary li a.active {
    color: var(--primary-color) !important;
    border-color: var(--primary-color);
}

.skin-tp .classic-tabs .nav.tabs-primary li a {
    color: #6c757d;
    font-weight: 500;
}

.skin-tp .classic-tabs .nav.tabs-primary li {
    margin-left: 0;
}

.skin-tp .classic-tabs .tab-content {
    padding: 1.5rem 0;
}

.md-v-line {
    position: absolute;
    border-right: 1px solid rgba(0, 0, 0, .125);
    height: 100%;
    top: 0;
    left: 54px;
}

.sortable.table thead th.descending::after {
    content: '\f078';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    padding-right: 3px;
    padding-left: .5rem;
}

.sortable.table thead th.ascending::after {
    content: '\f077';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    padding-right: 3px;
    padding-left: .5rem;
}

.trigger {
    padding: 1px 10px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
    background-color: #eee;
    color: #212121;
    display: inline-block;
    margin: 2px 5px;
    transition: box-shadow 0.55s;
}

.trigger:hover {
    transition: box-shadow 0.45s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.md-pills .show > .nav-link {
    background-color: #ffffff !important;
    box-shadow: unset !important;
}

/* animated bars */
.animated-bars {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-bars span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-bars span:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-bars span {
    background: var(--primary-color);
}

.animated-bars span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-bars span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-bars.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    left: 8px;
}

.animated-bars.open span:nth-child(2) {
    width: 0;
    opacity: 0;
}

.animated-bars.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 8px;
}

@media (min-width: 768px){
    .carousel-multi-item.v-2.items-2 .carousel-item-right.active,
    .carousel-multi-item.v-2.items-2 .carousel-item-next {
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    .carousel-multi-item.v-2.items-2 .carousel-item-left.active,
    .carousel-multi-item.v-2.items-2 .carousel-item-prev {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .carousel-multi-item.v-2.items-2 .carousel-item-right,
    .carousel-multi-item.v-2.items-2 .carousel-item-left {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    .carousel-multi-item.v-2.items-4 .carousel-item-right.active,
    .carousel-multi-item.v-2.items-4 .carousel-item-next {
        -webkit-transform: translateX(25%);
        -ms-transform: translateX(25%);
        transform: translateX(25%);
    }
    .carousel-multi-item.v-2.items-4 .carousel-item-left.active,
    .carousel-multi-item.v-2.items-4 .carousel-item-prev {
        -webkit-transform: translateX(-25%);
        -ms-transform: translateX(-25%);
        transform: translateX(-25%);
    }
    .carousel-multi-item.v-2.items-4 .carousel-item-right,
    .carousel-multi-item.v-2.items-4 .carousel-item-left {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    .carousel-multi-item.v-2.items-5 .carousel-item-right.active,
    .carousel-multi-item.v-2.items-5 .carousel-item-next {
        -webkit-transform: translateX(20%);
        -ms-transform: translateX(20%);
        transform: translateX(20%);
    }
    .carousel-multi-item.v-2.items-5 .carousel-item-left.active,
    .carousel-multi-item.v-2.items-5 .carousel-item-prev {
        -webkit-transform: translateX(-20%);
        -ms-transform: translateX(-20%);
        transform: translateX(-20%);
    }
    .carousel-multi-item.v-2.items-5 .carousel-item-right,
    .carousel-multi-item.v-2.items-5 .carousel-item-left {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.btn-toggle-pass {
    border: none;
    position: absolute;
    top: 11px;
    background: transparent;
    right: 0;
}

.btn-toggle-pass.active {
    color: var(--info-color);
}

//.select-wrapper.no-caret span.caret {
//    color: transparent !important;
//}

.product-addon-wrapper {
    border: 1px solid rgba(0,0,0,0.2);
    width: 100%;
}

.product-addon-label {
    transform: translate(0,-50%) !important;
    left: 1% !important;
    top: 0 !important;
    background-color: #fff;
    font-size: 0.8rem;
}
