/*.ch-cookie-consent {*/
/*    background-color: #fff;*/
/*    color: #000;*/
/*    width: 100%;*/
/*    z-index: 99999;*/
/*    padding: 35px 40px;*/
/*}*/

.ch-cookie-consent__title {
    color: #000;
    margin-top: 0;
}

.ch-cookie-consent__read-more {
    font-weight: bold;
    color: #000;
    text-decoration: none;
    display: block;
}

.ch-cookie-consent__read-more:hover {
    text-decoration: underline;
}

/*.ch-cookie-consent__form {*/
/*    margin-top: 40px;*/
/*}*/

.ch-cookie-consent__category {
    border-bottom: 1px solid #000;
    padding: 30px 0;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 641px) {
    .ch-cookie-consent__category {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.ch-cookie-consent__category:first-child {
    border-top: 1px solid #000;
}

.ch-cookie-consent__category-toggle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media screen and (max-width: 641px) {
    .ch-cookie-consent__category-toggle {
        margin-top: 20px;
    }
}

.ch-cookie-consent__category-toggle input {
    opacity: 0;
    position: absolute;
    left: -10000px;
}

.ch-cookie-consent__category-toggle input+label {
    background-repeat: no-repeat;
    background-position: 45px center;
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: all .2s;
    display: inline-block;
    margin-right: 15px;
    padding: 6px 28px 6px 10px;
    cursor: pointer;
    border-radius: 6px;
    width: 75px;
}

.ch-cookie-consent__category-toggle input+label::before,
.ch-cookie-consent__category-toggle input+label::after {
    content: '';
}

.ch-cookie-consent__category-toggle input:checked+label,
.ch-cookie-consent__category-toggle input+label:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-color: #fff;
}

.ch-cookie-consent__category-toggle input:checked+label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='#fff' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.ch-cookie-consent__category-title {
    color: #000;
    margin: 0;
}

.ch-cookie-consent__category-description {
    margin: 0;
}

.ch-cookie-consent--dark-theme {
    background-color: #000;
    color: #fff;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__title,
.ch-cookie-consent--dark-theme .ch-cookie-consent__read-more {
    color: #fff;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category {
    border-color: #fff;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category:first-child {
    border-color: #fff;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input+label {
    background-color: #000;
    color: #fff;
    border-color: #fff;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input:checked+label,
.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input+label:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input:checked+label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='#000' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.ch-cookie-consent--dark-theme .ch-cookie-consent__category-title {
    color: #fff;
}
